<!-- src/components/GlobalDialog.vue -->

<template>
  <v-dialog v-model="visible" max-width="300">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        {{ text }}
        <component :is="textSlot" />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="confirm">Confirm</v-btn>
        <v-btn @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      visible: (state) => state.dialog.visible,
      title: (state) => state.dialog.title,
      text: (state) => state.dialog.text,
      callback: (state) => state.dialog.callback,
      textSlot: (state) => state.dialog.textSlot,
    }),
  },
  methods: {
    ...mapActions(['closeDialog']),
    confirm() {
      if (this.callback) {
        this.callback();
      }
      this.closeDialog();
    },
    cancel() {
      this.closeDialog();
    },
  },
};
</script>
