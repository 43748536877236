<template>
  <v-card>
    <v-card-text>
      <v-form @submit.prevent="login">
        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          required
        ></v-text-field>
        <v-btn
          type="submit"
          block
        >Login</v-btn>
      </v-form>
    </v-card-text>
<!--    <v-card-actions>-->
<!--      <v-btn color="primary" block @click="dialog = false">Close Dialog</v-btn>-->
<!--    </v-card-actions>-->
  </v-card>
</template>
<script>
import {mapActions, mapState} from "vuex";
import UserDataService from "../../services/UserDataService";

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    ...mapActions(['setAuth', 'logout']),
    async login() {
      const loginResponse = await UserDataService.login({
        email: this.email,
        password: this.password,
      })
      this.setAuth(loginResponse);
      this.$forceUpdate();
    },
  },
}
</script>