import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./components/Welcome'),
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('./components/CollectionList'),
  },
  {
    path: '/collection/create',
    name: 'create-collection',
    component: () => import('./components/CollectionForm'),
    props: () => ({ create: true }),
  },
  {
    path: '/collection/update/:id_collection',
    name: 'update-collection',
    component: () => import('./components/Collection'),
    props: (route) => ({ id_collection: String(route.params.id_collection) }),
  },
  {
    path: '/collection/export/:id_collection',
    name: 'export-collection',
    component: () => import('./components/Exporter.vue'),
    props: (route) => ({ id_collection: String(route.params.id_collection) }),
  },
  {
    path: '/exports',
    name: 'export-list',
    component: () => import('./components/Exports.vue'),
    props: (route) => ({ id_collection: String(route.params.id_collection) }),
  },
  {
    path: '/thing/create/:id_collection',
    name: 'create-thing',
    component: () => import('./components/ThingForm'),
    props: (route) => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  {
    path: '/attribute/create/:id_collection',
    name: 'create-attribute',
    component: () => import('./components/AttributeForm'),
    props: (route) => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  {
    path: '/attribute/update/:id',
    name: 'update-attribute',
    component: () => import('./components/AttributeForm'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/attribute_groups/:id_collection',
    name: 'attribute_group-list',
    component: () => import('./components/AttributeGroupList'),
    props: (route) => ({ id_collection: String(route.params.id_collection) }),
  },
  {
    path: '/attribute_group/create/:id_collection',
    name: 'attribute_group-create',
    component: () => import('./components/AttributeGroupForm'),
    props: (route) => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  {
    path: '/attribute_group/update/:id',
    name: 'attribute_group-update',
    component: () => import('./components/AttributeGroupForm'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/thing/update/:id',
    name: 'update-thing',
    component: () => import('./components/ThingForm'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/attribute_select/update/:id',
    name: 'update-attribute_select',
    component: () => import('./components/AttributeSelectForm'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/crawler',
    name: 'crawler',
    component: () => import('./components/CrawlerList.vue'),
  },
  {
    path: '/crawler/update/:id',
    name: 'crawler-update',
    component: () => import('./components/CrawlerForm.vue'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/crawler_run/update/:id',
    name: 'crawler-run-update',
    component: () => import('./components/CrawlerRunForm.vue'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/crawler/create',
    name: 'crawler-create',
    component: () => import('./components/CrawlerForm.vue'),
    props: (route) => ({ create: true }),
  },
  {
    path: '/scripts',
    name: 'scripts',
    component: () => import('./components/Scripts.vue'),
  },
  {
    path: '/bulk-actions',
    name: 'bulk-actions',
    component: () => import('./components/BulkActionList.vue'),
  },
  {
    path: '/bulk-actions/update/:id',
    name: 'update-bulk-actions',
    component: () => import('./components/BulkActionForm.vue'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/bulk-actions/create',
    name: 'create-bulk-actions',
    component: () => import('./components/BulkActionForm.vue'),
    props: (route) => ({ create: true }),
  },
  {
    path: '/dynamic-image/list',
    name: 'dynamic-image-list',
    component: () => import('./components/dynamic-image/DynamicImageList.vue'),
  },
  {
    path: '/dynamic-image/create',
    name: 'dynamic-image-create',
    component: () => import('./components/dynamic-image/DynamicImageForm.vue'),
    props: () => ({ create: true }),
  },
  {
    path: '/dynamic-image/update/:id',
    name: 'dynamic-image-update',
    component: () => import('./components/dynamic-image/DynamicImageForm.vue'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/dynamic-image-data-source/list',
    name: 'dynamic-image-data-source-list',
    component: () => import('./components/dynamic-image/DynamicImageDataSourceList.vue'),
  },
  {
    path: '/dynamic-image-data-source/create',
    name: 'dynamic-image-data-source-create',
    component: () => import('./components/dynamic-image/DynamicImageDataSourceForm.vue'),
    props: () => ({ id: null, create: true }),
  },
  {
    path: '/dynamic-image-data-source/update/:id',
    name: 'dynamic-image-data-source-update',
    component: () => import('./components/dynamic-image/DynamicImageDataSourceForm.vue'),
    props: (route) => ({ id: String(route.params.id), create: false }),
  },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('./components/users/UserList.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
