<template>
  <v-navigation-drawer
  >
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-list
          v-bind="props"
        >
          <v-list-item
            v-if="auth"
            prepend-avatar="https://randomuser.me/api/portraits/men/1.jpg"
            title="Welcome"
            :subtitle="auth.email"
          ></v-list-item>

          <v-dialog
            v-if="!auth"
            v-model="loginDialog"
            width="500"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                color="primary"
                v-bind="props"
              >Login</v-list-item>
            </template>
            <Login></Login>
          </v-dialog>

          <v-dialog
            v-if="!auth && !isProduction"
            v-model="signupDialog"
            width="500"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                color="primary"
                v-bind="props"
              >Signup</v-list-item>
            </template>
            <Signup></Signup>
          </v-dialog>

        </v-list>
      </template>

      <v-list v-if="auth">
        <v-list-item>
          <v-list-item-title><v-btn @click="_logout" block>Logout</v-btn></v-list-item-title>
        </v-list-item>
      </v-list>

    </v-menu>


    <v-divider></v-divider>

    <v-list density="compact" nav v-if="isLoggedIn">
      <v-list-item to="/" prepend-icon="mdi-folder" title="Home" value="/"></v-list-item>
      <v-list-item to="/collections" prepend-icon="mdi-account-multiple" title="Collections" value="/collections"></v-list-item>
      <v-list-item to="/crawler" prepend-icon="mdi-star" title="Crawler" value="/crawler"></v-list-item>
      <v-list-item to="/scripts" prepend-icon="mdi-star" title="Scripts" value="/scripts"></v-list-item>
      <v-list-item to="/bulk-actions" prepend-icon="mdi-grid" title="Bulk Actions" value="/bulk-actions"></v-list-item>
      <v-list-item to="/dynamic-image/list" prepend-icon="mdi-grid" title="Dynamic Images" value="/dynamic-image/list"></v-list-item>
      <v-list-item to="/exports" prepend-icon="mdi-grid" title="Exports" value="/exports"></v-list-item>
      <v-list-item to="/users/list" prepend-icon="mdi-grid" title="Users" value="/users/list"></v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<!-- Registration Form -->
<!--      <div>-->
<!--        <h3>Register</h3>-->
<!--        <input v-model="registerEmail" placeholder="Email" />-->
<!--        <input v-model="registerPassword" placeholder="Password" type="password" />-->
<!--        <button @click="register">Register</button>-->
<!--      </div>-->
<script>
import {mapActions, mapState} from "vuex";
import supabase from "../supabase";
import Login from "./users/Login";
import Signup from "./users/Signup";

export default {
  components: {
    Login,
    Signup,
  },
  data() {
    return {
      loginDialog: false,
      signupDialog: false,
    };
  },
  computed: {
    ...mapState(['auth', 'isProduction']),
    isLoggedIn() {
      return this.auth !== null;
    },
  },
  methods: {
    ...mapActions(['setAuth', 'logout']),
    _logout() {
      supabase.auth.signOut()
      this.logout()
    },
    async register() {
      const { user, error } = await supabase.auth.signUp({
        email: this.registerEmail,
        password: this.registerPassword,
      })

      if (error) {
        console.error('Error registering:', error);
      } else {
        this.setAuth(user);
      }
    },
  },
}
</script>
