import { createApp } from 'vue'
import { createVuetify } from "vuetify"
import VueKonva from 'vue-konva'
import './styles/main.scss'
import CKEditor from '@ckeditor/ckeditor5-vue'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from './App.vue'
import router from './router'
import store from './store'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
  },
})

const app = createApp(App)
app.use(store)
store.dispatch('init')
app.use(vuetify)
app.use(VueKonva, { prefix: 'Konva'})

app.use(router)
app.use(CKEditor)
app.mount('#app')
