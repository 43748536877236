import { createStore } from 'vuex';

const isProduction = process.env.NODE_ENV === 'production';
const isDevelopment = !isProduction;

const defaultDialog = {
  visible: false,
  title: '',
  text: '',
  callback: null,
  textSlot: null,
}

const defaultBulkAction = {
  stepsAdded: 0,
}

const store = createStore({
  state: {
    isProduction,
    isDevelopment,
    auth: null,
    dialog: { ...defaultDialog },
    bulkAction: { ...defaultBulkAction },
  },
  mutations: {
    SET_AUTH(state, user) {
      state.auth = user;
    },
    CLEAR_AUTH(state) {
      state.auth = null;
    },
    SET_DIALOG(state, dialog) {
      state.dialog = { ...state.dialog, ...dialog };
    },
    BULK_ACTION_ADD_STEP(state) {
      state.bulkAction.stepsAdded += 1;
    },
  },
  actions: {
    openDialog({ commit }, { title, text, callback, textSlot }) {
      commit('SET_DIALOG', { visible: true, title, text, callback, textSlot });
    },
    closeDialog({ commit }) {
      commit('SET_DIALOG', { ...defaultDialog });
    },
    setAuth({ commit }, auth) {
      commit('SET_AUTH', auth);
      localStorage.setItem('auth', JSON.stringify(auth));
    },
    logout({ commit }) {
      commit('CLEAR_AUTH');
      localStorage.removeItem('auth');
    },
    init({ commit }) {
      const auth = localStorage.getItem('auth');
      if (auth) {
        commit('SET_AUTH', JSON.parse(auth));
      }
    },
    bulkActionAddStep({ commit }) {
      commit('BULK_ACTION_ADD_STEP');
    },
  },
});

export default store;
