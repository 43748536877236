<template>
  <suspense>
    <v-app>
      <Navigation></Navigation>
      <v-main>
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
        <GlobalDialog />
      </v-main>
    </v-app>
  </suspense>
</template>

<script>
import Navigation from './components/Navigation'
import GlobalDialog from './components/custom/GlobalDialog'

export default {
  name: "app",
  components: {
    Navigation,
    GlobalDialog,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
