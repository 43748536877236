import http from '../http-common'

class UserDataService {

  create (data) {
    return http.post('/user', data)
  }

  findAll () {
    return http.get('/user')
  }

  findOne (id) {
    return http.get(`/user/${id}`)
  }

  remove (id) {
    return http.delete(`/user/${id}`)
  }

  login(data) {
    return http.post('/user/login', data)
  }

  signup(data) {
    return http.post('/user/signup', data)
  }

}

export default new UserDataService()
